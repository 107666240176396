@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.withPadding {
  @include to(1) {
    padding: 40px 20px;
  }

  @include from(2) {
    padding: 150px 50px;
  }
}

.callout {
  @include to(2) {
    max-width: 90%;
    white-space: normal;
  }

  @include from(3) {
    white-space: pre-line;
  }

  .withBackground & {
    z-index: 1;
    color: $fontColorLight;
    font-weight: 600;
  }

  .withoutBackground.large & {
    font-size: 28px;

    > :first-child {
      font-size: 38px;
    }
  }

  .withBackground.large & {
    @include to(1) {
      font-size: 48px;
      line-height: 54px;
    }

    @include from(2) {
      font-size: 70px;
      line-height: 75px;
    }
  }

  .withoutBackground.small & {
    font-size: 15px;
  }

  .withBackground.small & {
    font-size: 15px;
  }
}

.background {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}

.image {
  margin: 5px 0 5px 30px;
}
