@import 'breakpoints.scss';
@import 'utils.scss';

.container {
  text-align: center;

  @include from(3) {
    margin: 30px 0 0;
    font-size: 21px;
  }

  @include to(2) {
    margin: 10px 0 0;
  }
}

.image {
  @include from(3) {
    width: 100px;
    height: 100px;
    margin-bottom: 25px;
  }

  @include to(2) {
    width: 82px;
    height: 82px;
    margin-bottom: 22px;
  }
}

.quote {
  @include markdownRemoveMargins;
  @include quotations;

  @include from(3) {
    max-width: 580px;
    margin: 0 auto 22px;
  }

  @include to(2) {
    max-width: 300px;
    margin: 0 auto 18px;
  }
}

.name {
  font-weight: 600;
}
