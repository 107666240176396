@import 'utils.scss';
@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  width: 200px;
  text-align: center;

  &::after {
    content: ' ';
    display: block;
    width: 130px;
    height: 3px;
    margin: 0 auto;
  }
}

@each $theme, $themeColors in $themes {
  .#{$theme}.container::after {
    background-color: map-get($themeColors, 'secondary');
  }
}

.image {
  display: none;
}

.title {
  font-size: 38px;
  font-weight: 600;
}

.description {
  margin-bottom: 40px;
  font-size: 20px;
}

.action {
  display: none;
}
