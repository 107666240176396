.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 170px 0;
}

.title {
  margin-bottom: 40px;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
}

.cards {
  flex-direction: column;
  align-items: center;

  > * {
    margin-bottom: 40px;
  }

  > :last-child {
    margin-bottom: 0;
  }
}
