@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  @include from(3) {
    padding: 40px 30px;
  }

  @include to(2) {
    padding: 40px 0 20px;
  }
}

.title {
  font-style: italic;
  font-weight: 600;
  line-height: 23px;
  text-align: center;

  @include from(3) {
    margin-bottom: 52px;
    color: $grey400;
    font-size: 32px;
  }

  @include to(2) {
    margin-bottom: 27px;
    color: $grey600;
    font-size: 22px;
  }
}

.logos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @include to(2) {
    padding: 0 30px;
  }

  > * {
    opacity: 0.5;

    @include from(3) {
      height: 40px;
      margin: 0 20px 30px;
    }

    @include to(2) {
      height: 22px;
      margin: 0 10px 20px;
    }
  }
}
